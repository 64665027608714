body {
    margin: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;

    font-family: 'Varela', sans-serif;
    font-size: 1rem;
    /*noinspection CssInvalidPropertyValue*/
    height: -webkit-fill-available;
}

html {
    /*noinspection CssInvalidPropertyValue*/
    height: -webkit-fill-available;
}

h1, h2, h3, h4, h5 {
    font-family: 'Titillium Web', sans-serif;
    margin-block: unset;
}

p {
    margin-block: 0.5em;
}
